var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { maxHeight, overflow, space } from 'styled-system';
import { Text, SmallHeading } from '../../common/components/Text';
import { textWithEllipsis } from '../../common/theme/helper';
var TextWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
TextWrapper.defaultProps = {
    m: 'sp_16',
};
var Headline = styled(SmallHeading)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), maxHeight, textWithEllipsis());
Headline.defaultProps = __assign(__assign({}, SmallHeading.defaultProps), { mb: ['sp_2', null, null, 'sp_4'], fontSize: ['fs_16', null, '18px', 'fs_20'], lineHeight: ['lh_20', null, 'lh_22', 'lh_24'], color: 'sys.neutral.border.strong' });
var Subline = styled(Text)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"], ["\n  ", ";\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"])), overflow);
Subline.defaultProps = __assign(__assign({}, Text.defaultProps), { fontSize: ['fs_16', null, null, 'fs_18'], lineHeight: ['lh_20', null, null, 'lh_24'], overflow: 'hidden', color: 'sys.neutral.border.hover' });
export var CardTextBlock = function (_a) {
    var subline = _a.subline, headline = _a.headline;
    return (React.createElement(TextWrapper, null,
        headline && React.createElement(Headline, null, headline),
        subline && React.createElement(Subline, null, subline)));
};
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { border, borderColor, borderStyle, borderWidth, display, height, width, } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import { ResponsiveLazyImage } from '../../common/components/LazyImage';
import { Link } from '../../common/components/Link';
import { useIsObserved } from '../../common/hooks/intersection-observer';
import { borderRadiusBL, borderRadiusBR, } from '../../common/theme/system-utilities';
import { CardTextBlock } from './CardTextBlock';
import { useTrackingEvents } from './hooks';
var CardLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), display, height);
CardLink.defaultProps = {
    display: 'flex',
    height: '100%',
    width: 1,
};
var CardPicture = styled(ResponsiveLazyImage)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), width);
CardPicture.defaultProps = {
    width: 1,
};
var CardContent = styled(Flex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), borderRadiusBL, borderRadiusBR, borderStyle, borderWidth, borderColor, border, width);
CardContent.defaultProps = {
    as: 'article',
    flexDirection: 'column',
    borderRadiusBL: 'small',
    borderRadiusBR: 'small',
    width: 1,
    borderStyle: 'solid',
    borderWidth: 'small',
    borderColor: 'sys.neutral.border.inactive',
};
export var Card = function (_a) {
    var href = _a.href, subline = _a.subline, headline = _a.headline, image = _a.image, index = _a.index;
    var _b = __read(useIsObserved(), 2), ref = _b[0], isObserved = _b[1];
    var _c = useTrackingEvents(index, headline), onImageView = _c.onImageView, onImageClick = _c.onImageClick;
    var label = headline || subline;
    useEffect(function () {
        if (isObserved) {
            onImageView();
        }
    }, [isObserved]);
    return (React.createElement(CardLink, { ref: ref, href: href, "aria-label": label, onClick: onImageClick },
        React.createElement(CardContent, null,
            React.createElement(CardPicture, __assign({}, image)),
            label && React.createElement(CardTextBlock, { headline: headline, subline: subline }))));
};
var templateObject_1, templateObject_2, templateObject_3;

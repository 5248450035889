import { useCallback } from 'react';
import { dispatchTrackingEventGA4 } from '../../common/tracking/dispatchTrackingEventGA4';
var EventName;
(function (EventName) {
    EventName["CardGridView"] = "card-grid_view";
    EventName["CardGridClick"] = "card-grid_click";
})(EventName || (EventName = {}));
export var useTrackingEvents = function (listPosition, title) {
    var cardTitle = title !== null && title !== void 0 ? title : '';
    var onImageView = useCallback(function () {
        dispatchTrackingEventGA4({
            event: EventName.CardGridView,
            element: 'image',
            title: cardTitle,
            list_position: listPosition,
        });
    }, [cardTitle, listPosition]);
    var onImageClick = useCallback(function () {
        dispatchTrackingEventGA4({
            event: EventName.CardGridClick,
            element: 'image',
            title: cardTitle,
            list_position: listPosition,
        });
    }, [cardTitle, listPosition]);
    return { onImageView: onImageView, onImageClick: onImageClick };
};

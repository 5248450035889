var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Grid, GridItem } from '../../common/components/Grid';
import { SmallHeading } from '../../common/components/Text';
import { breakpoint } from '../../common/theme/helper';
import { Card } from './Card';
var CardGridItem = styled(GridItem)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (props) {
    return props.title
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          &:nth-of-type(-n + 2) {\n            margin-top: 0;\n          }\n          ", " {\n            &:nth-of-type(-n + 3) {\n              margin-top: 0;\n            }\n          }\n          ", " {\n            &:nth-of-type(-n + 5) {\n              margin-top: 0;\n            }\n          }\n        "], ["\n          &:nth-of-type(-n + 2) {\n            margin-top: 0;\n          }\n          ", " {\n            &:nth-of-type(-n + 3) {\n              margin-top: 0;\n            }\n          }\n          ", " {\n            &:nth-of-type(-n + 5) {\n              margin-top: 0;\n            }\n          }\n        "])), breakpoint(0)(props), breakpoint(1)(props)) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          &:nth-of-type(-n + 1) {\n            margin-top: 0;\n          }\n          ", " {\n            &:nth-of-type(-n + 2) {\n              margin-top: 0;\n            }\n          }\n          ", " {\n            &:nth-of-type(-n + 4) {\n              margin-top: 0;\n            }\n          }\n        "], ["\n          &:nth-of-type(-n + 1) {\n            margin-top: 0;\n          }\n          ", " {\n            &:nth-of-type(-n + 2) {\n              margin-top: 0;\n            }\n          }\n          ", " {\n            &:nth-of-type(-n + 4) {\n              margin-top: 0;\n            }\n          }\n        "])), breakpoint(0)(props), breakpoint(1)(props));
});
CardGridItem.defaultProps = {
    w: [1, 1 / 2, 1 / 4],
    mt: ['sp_16', 'sp_20', 'sp_24', 'sp_32'],
};
var CardGridHeader = styled(SmallHeading)(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
CardGridHeader.defaultProps = __assign(__assign({}, SmallHeading.defaultProps), { as: 'h3', color: 'sys.neutral.border.strong', fontSize: ['fs_18', 'fs_20', null, 'fs_28'], lineHeight: ['lh_22', 'lh_24', null, 'lh_32'], mb: ['sp_16', 'sp_20', 'sp_24', 'sp_32'], mt: 'sp_0' });
var CardGrid = function (_a) {
    var items = _a.items, title = _a.title;
    return (React.createElement(Grid, null,
        title && (React.createElement(GridItem, null,
            React.createElement(CardGridHeader, null, title))),
        items.map(function (item, index) { return (React.createElement(CardGridItem, { key: index, title: title },
            React.createElement(Card, __assign({ index: index }, item)))); })));
};
export default CardGrid;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
